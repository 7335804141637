import httpClient from "@/services/httpClient";

var mime = require("mime-types");

class MDGenerateService {
	async generatePSCL(format, company_id, office_id, survey_id, ignore_office, year, step) {
		if (ignore_office == "true") {
			ignore_office = 1;
		}
		return httpClient({
			method: "post",
			url: `/pscl/report/${format}/${company_id}/${office_id}/${survey_id}/${ignore_office}/${year}/${step}.json`,
		});
	}

	async getUrl(format, company_id, office_id, survey_id, ignore_office, year, step) {
		if (ignore_office == "true") {
			ignore_office = 1;
		}
		return httpClient({
			method: "get",
			url: `/pscl/getUrl/${format}/${company_id}/${office_id}/${survey_id}/${ignore_office}/${year}/${step}.json`,
		});
	}

	async pushToNextCloud(company_id, office_id, survey_id, ignore_office, year, step) {
		//convert ignore_office to integer
		if (ignore_office == "true") {
			ignore_office = 1;
		}
		return httpClient({
			method: "post",
			url: `/pscl/pushTo/nextcloud/${company_id}/${office_id}/${survey_id}/${ignore_office}/${year}/${step}.json`,
		});
	}

	async getStatus(company_id, office_id, survey_id) {
		return httpClient({
			method: "get",
			url: `/pscl/get_queue_status/${company_id}/${office_id}/${survey_id}.json`
		});
	}

	async pullFromNextCloud(company_id, office_id, survey_id, ignore_office, year, step) {
		//convert ignore_office to integer
		if (ignore_office == "true") {
			ignore_office = 1;
		}
		return httpClient({
			method: "post",
			url: `/pscl/pullFrom/nextcloud/${company_id}/${office_id}/${survey_id}/${ignore_office}/${year}/${step}.json`,
		});
	}

	async getFolder(company_id, office_id, survey_id, ignore_office, year) {
		//convert ignore_office to integer
		if (ignore_office == "true") {
			ignore_office = 1;
		}
		return httpClient({
			method: "get",
			url: `/pscl/get_folder/${company_id}/${office_id}/${survey_id}/${ignore_office}/${year}.json`
		});
	}

	async getFile(company_id, office_id, survey_id, ignore_office, year, name) {
		//Url encode name
		name = encodeURIComponent(name);

		return httpClient({
			method: "get",
			url: `/pscl/get_file/${company_id}/${office_id}/${survey_id}/${ignore_office}/${year}.json?fname=${name}`
		});
	}

	async saveFile(company_id, office_id, survey_id, ignore_office, year, name, content) {
		//Url encode name
		name = encodeURIComponent(name);
		return httpClient({
			method: "post",
			url: `/pscl/save_file/${company_id}/${office_id}/${survey_id}/${ignore_office}/${year}.json?fname=${name}`,
			data: {
				content,
			},
		});
	}

	async uploadImages(company_id, office_id, survey_id, ignore_office, year, formData) {
		//Prepare for file upload

		return httpClient({
			method: "post",
			data: formData,
			headers: {
            'Content-Type': 'multipart/form-data'
          	},
			url: `/pscl/upload_images/${company_id}/${office_id}/${survey_id}/${ignore_office}/${year}.json`,
		});
	}



}

export default new MDGenerateService();
