<template>

    <div class="container-fluid">

        <BreadCrumb :company_id="parseInt(company_id)" :office_id="parseInt(office_id)" :survey_id="parseInt(survey_id)"
            :breadcrumb="breadcrumb">
        </BreadCrumb>

        <div class="row">
            <div class="col-md-6">
                <b-dropdown id="dropdown-genera" text="Generazione automatica" class="m-md-2" split>
                    <b-dropdown-item @click="generateMobilityLabel()" class="ml-3" size="sm">
                        <b-icon icon="tags" aria-hidden="true"></b-icon>
                        Genera Mobility Label
                    </b-dropdown-item>

                    <b-dropdown-item @click="generateSurvey()" class="ml-3" size="sm">
                        <b-icon icon="pie-chart" aria-hidden="true"></b-icon>
                        Genera Analisi Domande
                    </b-dropdown-item>

                    <b-dropdown-item @click="generateMeasures()" class="ml-3" size="sm">
                        <b-icon icon="brush" aria-hidden="true"></b-icon>
                        Genera Misure PSCL
                    </b-dropdown-item>

                    <b-dropdown-divider></b-dropdown-divider>

                    <b-dropdown-item variant="danger" @click="generateMd()" class="ml-3" size="sm">
                        <b-icon variant="danger" icon="exclamation-triangle-fill" aria-hidden="true"></b-icon>
                        (Ri)Genera tutto il piano
                    </b-dropdown-item>
                </b-dropdown>

                <b-dropdown id="dropdown-nextcloud" text="Nextcloud" class="m-md-2" split>
                    <b-dropdown-item variant="secondary-outline" @click="openNextCloud()" sm class="ml-3"><b-icon
                            icon="cloud" class="mr-2"></b-icon> Apri Nextcloud</b-dropdown-item>
                    <b-dropdown-item variant="secondary-outline" @click="pullFromNextCloud()" sm class="ml-3"><b-icon
                            icon="cloud-download" class="mr-2"></b-icon> Importa da Nextcloud</b-dropdown-item>
                    <b-dropdown-item variant="secondary" @click="pushToNextCloud()" sm class="ml-3"><b-icon
                            icon="cloud-upload" class="mr-2"></b-icon> Esporta su Nextcloud</b-dropdown-item>
                </b-dropdown>
            </div>
            <div class="col-md-6 d-flex flex-row-reverse">
                <b-button-group>
                    <b-button variant="primary" @click="generatePdf()" class="ml-3">
                        <b-spinner small v-if="generatingPdf"></b-spinner>
                        <span class="sr-only">Loading...</span>

                        <b-icon icon="file-pdf" aria-hidden="true"></b-icon>
                        Genera Documento PDF
                        <b-badge variant="warning">lento</b-badge>
                    </b-button>
                    <b-button :variant="pdfButtonVariant" :href="pdfUrl" target="anteprima_html"
                        :disabled="pdfUrl == ''" title="Anteprima">
                        <b-icon icon="arrow-up-right-square" aria-hidden="true"></b-icon>
                    </b-button>
                </b-button-group>
                <b-button-group>
                    <b-button variant="primary" @click="generateHTML()">
                        <b-spinner small v-if="generatingHtml"></b-spinner>
                        <span class="sr-only">Loading...</span>

                        <b-icon icon="file-code" aria-hidden="true"></b-icon>
                        Genera HTML

                    </b-button>
                    <b-button :variant="htmlButtonVariant" :href="htmlUrl" target="anteprima_html"
                        :disabled="htmlUrl == ''" title="Anteprima">
                        <b-icon icon="arrow-up-right-square" aria-hidden="true"></b-icon>
                    </b-button>
                </b-button-group>
            </div>
        </div>
        <div class="row mt-3" style="height: 80vh;">
            <div class="col-md-2 h-100">

                <div class="tree">
                    <h5>Struttura del PSCL</h5>
                    <directory-tree :tree="dirTree" @open="previewFile($event)" parent=""></directory-tree>
                </div>
            </div>
            <div class="col-md-10 h-100">
                <v-md-editor id="md-editor" v-model="text" class="w-100 h-100" @save="saveMd()" ref="m48editor"
                    :disabled-menus="[]" @upload-image="handleUploadImage"></v-md-editor>
            </div>
        </div>
        <b-modal id="modal-immagine" title="Immagine Selezionata" ok-title="Inserisci al cursore" cancel-title="Chiudi"
            @ok="insertImage()">
            <b-img :src="`${baseUrl}/${this.activePictureName}`" fluid style="max-height: 50vh;"></b-img>
        </b-modal>
    </div>

</template>

<script>
import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
// highlightjs
import hljs from 'highlight.js';
import enUS from '@kangc/v-md-editor/lib/lang/en-US';

import DirectoryTree from '../components/DirectoryTree.vue';
import Util from "@/mixing/util";
import MDGenerate from "../md_generate.service";
import BreadCrumb from '@/components/BreadCrumb.vue';

VMdEditor.use(githubTheme, {
    Hljs: hljs,
});
VMdEditor.lang.use('en-US', enUS);


export default {
    name: 'MdEditor',
    data() {
        return {
            text: '',
            dirTree: {},
            loading: false,
            message: "Fai click su uno dei pulsanti per generare il piano",
            lock: [false, false, false, false, false],  //Stato dei 5 step
            company_id: this.$route.params.company_id,
            office_id: this.$route.params.office_id,
            survey_id: this.$route.params.survey_id,
            ignore_office: this.$route.params.ignore_office,
            activeFileName: '',
            activePictureName: '',
            baseUrl: '',
            oldText: '',
            htmlUrl: '',
            pdfUrl: '',
            generatingHtml: false,
            generatingPdf: false,
            breadcrumb: [
                { text: 'Azienda', href: '/companies' },
                { text: 'Sede', href: '/offices' },
                { text: 'Questionario', href: '/survey' },
                { text: 'PSCL', href: `/pscl?company_id=${this.$route.params.company_id}&office_id=${this.$route.params.office_id}&survey_id=${this.$route.params.survey_id}` },
                { text: 'Editor', active: true }
            ],
        };
    },
    mixins: [Util],
    async created() {
        this.getYear();
        if (this.year == 'TUTTI') {
            this.year = null;
        }
        this.updateDirTree();

        //Prendo l'url generale della cartella
        let l = await MDGenerate.getUrl("Md2Html", this.company_id, this.office_id, this.survey_id, this.ignore_office, this.year, 1);
        this.baseUrl = l.data.url;

        this.$root.$on('open', async (name) => {
            if (this.dirty) {
                await this.askToSave();
            }
            this.previewFile(name);
        });
    },
    components: {
        VMdEditor,
        DirectoryTree,
        BreadCrumb
    },
    computed: {
        htmlButtonVariant() {
            return this.htmlUrl ? 'success' : 'outline-primary';
        },
        pdfButtonVariant() {
            return this.pdfUrl ? 'success' : 'outline-primary';
        },
        dirty() {
            return this.text !== this.oldText;
        }
    },
    methods: {
        async updateDirTree() {
            this.dirTree = await MDGenerate.getFolder(this.company_id, this.office_id, this.survey_id, this.ignore_office, this.year);
            this.dirTree = this.dirTree?.data.directoryTree;
            if (this.dirTree[0] && !this.activeFileName) {
                this.previewFile(this.dirTree[0]);
            }
        },
        insertImage() {
            let editor = this.$refs.m48editor;
            editor.insert(() => {
                return {
                    text: `![Description](${this.activePictureName})`,
                }
            });
        },
        async previewFile(value) {

            if (value.endsWith('.md') || value.endsWith('.html')) {
                //Se è modificato chiedi di salvare il file aperto prima di aprirne un altro
                this.activeFileName = value;

                let f = await MDGenerate.getFile(this.company_id, this.office_id, this.survey_id, this.ignore_office, this.year, value);
                this.text = f.data;
                this.oldText = this.text;
            }
            if (value.endsWith('.png') || value.endsWith('.jpg')|| value.endsWith('.jpeg') || value.endsWith('.svg')) {
                // Remove leading slash from value
                if (value.startsWith('/')) {
                    value = value.substring(1);
                }

                this.activePictureName = value;
                this.$bvModal.show('modal-immagine');
            }
        },

        async generateHTML() {
            this.generatingHtml = true;
            if (this.oldText != this.text) {
                await this.askToSave();
            }
            await MDGenerate.generatePSCL("Md2Html", this.company_id, this.office_id, this.survey_id, this.ignore_office, this.year, 999);
            let l = await MDGenerate.getUrl("Md2Html", this.company_id, this.office_id, this.survey_id, this.ignore_office, this.year, 1);
            this.htmlUrl = `${l.data.url}/pscl.html`;
            this.generatingHtml = false;
        },

        async generatePdf() {
            this.generatingPdf = true;
            if (this.oldText != this.text) {
                await this.askToSave();
            }
            await MDGenerate.generatePSCL("pdf", this.company_id, this.office_id, this.survey_id, this.ignore_office, this.year, 2);
            let l = await MDGenerate.getUrl("pdf", this.company_id, this.office_id, this.survey_id, this.ignore_office, this.year, 2);
            this.pdfUrl = `${l.data.url}/pscl.pdf`;
            this.generatingPdf = false;
        },

        async generateMd() {
            this.$bvModal.msgBoxConfirm('Se rigeneri il piano tutte le modifiche che hai fatto saranno sovrascitte.', {
                title: 'Sei sicuro?',
                okVariant: 'danger',
                okTitle: 'RIGENERA',
                cancelTitle: 'NON FARLO!',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(async value => {
                    if (value) {
                        await MDGenerate.generatePSCL("md", this.company_id, this.office_id, this.survey_id, this.ignore_office, this.year, 1);
                        this.$bvToast.toast("Generazione avvenuta correttamente", {
                            title: "Generazione ok",
                            autoHideDelay: 2000,
                            appendToast: true,
                            variant: "success",
                        });
                        this.updateDirTree();
                    }
                })
                .catch(err => {
                    this.$bvToast.toast("Problema durante la generazione del modello di PSCL", {
                        title: "Generazione Fallita",
                        autoHideDelay: 2000,
                        appendToast: true,
                        variant: "warning",
                    });
                })
        },

        async generateSurvey() {
            this.$bvModal.msgBoxConfirm('Se rigeneri il questionario utente saranno cancellate tutte le modifiche in quella sezione.', {
                title: 'Sei sicuro?',
                okVariant: 'danger',
                okTitle: 'RIGENERA',
                cancelTitle: 'NON FARLO!',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(async value => {
                    if (value) {
                        await MDGenerate.generatePSCL("MdSurvey", this.company_id, this.office_id, this.survey_id, this.ignore_office, this.year, 1);
                        this.$bvToast.toast("Generazione avvenuta correttamente", {
                            title: "Generazione ok",
                            autoHideDelay: 2000,
                            appendToast: true,
                            variant: "success",
                        });
                        this.updateDirTree();
                    }
                })
                .catch(err => {
                    this.$bvToast.toast("Problema durante la generazione del questionario", {
                        title: "Generazione Fallita",
                        autoHideDelay: 2000,
                        appendToast: true,
                        variant: "warning",
                    });
                })
        },


        async generateMeasures() {
            this.$bvModal.msgBoxConfirm('Se rigeneri le misure saranno cancellate tutte le modifiche in quella sezione.', {
                title: 'Sei sicuro?',
                okVariant: 'danger',
                okTitle: 'RIGENERA',
                cancelTitle: 'NON FARLO!',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(async value => {
                    if (value) {
                        await MDGenerate.generatePSCL("MdMeasures", this.company_id, this.office_id, this.survey_id, this.ignore_office, this.year, 1);
                        this.$bvToast.toast("Generazione avvenuta correttamente", {
                            title: "Generazione ok",
                            autoHideDelay: 2000,
                            appendToast: true,
                            variant: "success",
                        });
                        this.updateDirTree();
                    }
                })
                .catch(err => {
                    this.$bvToast.toast("Problema durante la generazione delle misure", {
                        title: "Generazione Fallita",
                        autoHideDelay: 2000,
                        appendToast: true,
                        variant: "warning",
                    });
                })
        },

        async saveMd() {
            await MDGenerate.saveFile(this.company_id, this.office_id, this.survey_id, this.ignore_office, this.year, this.activeFileName, this.text);
            this.$bvToast.toast("Salvataggio avvenuto correttamente", {
                title: "Salvataggio ok",
                autoHideDelay: 2000,
                appendToast: true,
                variant: "success",
            });
            this.oldText = this.text;
        },
        absoluteUrls(text, next) {
            console.log('absoluteUrls');
            next = text.replace(/(src|href)="(?!http|\/)([^"]+)"/g, '$1="/https://api.mobility48.it/Mobility48/$2"');
        },
        async askToSave() {
            await this.$bvModal.msgBoxConfirm('Vuoi salvare le modifiche?', {
                title: 'Salvataggio',
                okVariant: 'success',
                okTitle: 'SALVA',
                cancelTitle: 'NON SALVARE',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if (value) {
                        this.saveMd();
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },

        async pushToNextCloud() {
            if (this.lock[1]) {
                alert("Comando già in corso, attendere la fine dell'operazione");
                return;
            }
            try {
                this.lock[1] = true;
                const response = await MDGenerate.pushToNextCloud(this.company_id, this.office_id, this.survey_id, this.ignore_office, this.year, 1);
                this.message = response.message;
            } catch (error) {
                this.lock[1] = false;
                this.message = error.message;
            }
        },

        async openNextCloud() {
            let url = "https://cloud.mobilitysquare.eu/apps/files/?dir=/Moma/Mobility48/pscl";
            //open link in a new window
            window.open(url, '_blank', 'noreferrer');
        },

        async pullFromNextCloud() {
            if (this.lock[1]) {
                alert("Comando già in corso, attendere la fine dell'operazione");
                return;
            }
            try {
                this.lock[2] = true;
                const response = await MDGenerate.pullFromNextCloud(this.company_id, this.office_id, this.survey_id, this.ignore_office, this.year, 2);
                this.message = response.message;
            } catch (error) {
                this.lock[2] = false;
                this.message = error.message;
            }
        },

        async handleUploadImage(event, insertImage, files) {
            // Get the files and upload them to the file server, then insert the corresponding content into the editor
            console.log(files);

            //Faccio il push dei file sul backend nella cartella images
            let formData = new FormData();
            formData.append('file', files[0]);
            try {
                const response = await MDGenerate.uploadImages(this.company_id, this.office_id, this.survey_id, this.ignore_office, this.year, formData);            
            } catch (error) {
                this.$bvToast.toast("Errore durante il caricamento dell'immagine", {
                    title: "Caricamento Fallito",
                    autoHideDelay: 2000,
                    appendToast: true,
                    variant: "warning",
                });
            }            
            this.updateDirTree();
            // Here is just an example
            insertImage({
                url:
                    this.baseUrl + '/images/' + files[0].name,
                desc: 'Immagine',
                // width: 'auto',
                // height: 'auto',
            });
        },
    },
    watch: {

    }
};
</script>


<style scoped>
@import url('https://api.mobility48.test/Mobility48/pscl/shared/css/mobilitysquare.scss');
@import url('https://api.mobility48.test/Mobility48/pscl/shared/css/report.scss');


.report {
    overflow-y: auto;
    border: 1px solid silver;
}
</style>
</div>